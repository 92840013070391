import { Link } from "react-router-dom";
import pieChart from "./image/pieChart.png";
import bottleOne from "./image/bottle50.svg";
import bottleTwo from "./image/bottle97.svg";
import bannerSeven from "./image/banner/banner7.png";
import bannerNine from "./image/banner/banner4.png";
import Header from "./component/Header";
import FormDownload from "./component/FormDownload";
import FormDownloadSub from "./component/FormDownloadSub";
import banner2img from "./image/banner2img.png";
import CardContainer from './component/CardContainer';
import PieChartList  from "./component/PieChartList";

import CircleChartList from "./component/CircleChartList";
import NineBarGraphList from "./component/NineBarGraphList";
import BarChartList from "./component/BarChartList";




const bannerSecondData = [
    "企業現時的營銷策略",
    "企業對數碼營銷策略態度",
    "企業對數碼營銷代理的情況",
    "企業對公司內部聘用及培訓營銷人才情況",
    "企業在數碼營銷上的預算",
    "企業對於網頁及電子商店的情況",
    "企業對數碼營銷的期望等",
];
const BannerSecondLi = () => {
    return bannerSecondData.map((item, index) => {
        if (index + 1 < bannerSecondData.length) {
            return <li key={index}>{item + "；"}</li>;
        } else {
            return <li key={index}>{item + "。"}</li>;
        }
    });
};
const App = () => {
    return (
        <>  
            <Header />
            <div className="indexBannerFirst">
                <div className="firstDiv">
                    <p className="font14px colorWhite title">
                        企業轉營必須知道的數碼媒體營銷數據
                    </p>
                    <h3 className="font48px fontWeight400 colorWhite">
                        2021-2022年
                    </h3>
                    <h1 className="colorOrange font72px fontWeight900">
                        數碼轉型報告
                    </h1>
                    <div className="whiteLine"></div>
                    <p className="font16px colorWhite content">
                        香港企業在2021至2022年間面對經營環境的快速轉變下，愈來愈多企業進行數碼轉型。您是否正在尋找最新的數碼媒體營銷數據，以便規劃將來的數碼營銷推廣策略？雖然數碼營銷是必然的趨勢，但許多香港企業在執行時面對著各式各樣的挑戰。
                    </p>
                    <Link to="/" className="colorWhite smallBtn">
                        了解更多
                    </Link>
                </div>
                <div>
                    <FormDownload />
                </div>
            </div>
            <div className="indexBannerSecond">
                <div className="firstDiv">
                    <h2 className="colorOrange font36px fontWeight700">報告總覽</h2>
                    <h3 className="colorWhite font24px fontWeight700">
                        為了讓香港企業進行數碼轉型時更加順利，Tech on Fire根據香港企業的最新信息編制了一份數碼轉型報告，當中包括以下資料：
                    </h3>
                    <ul className="colorWhite font20px fontWeight400">
                        <BannerSecondLi />
                    </ul>
                </div>
                <div className="secondDiv">
                    <img src={banner2img} alt="" />
                </div>
            </div>
            <div className="indexBannerThird">
                <h2 className="colorBlue font72px fontWeight900">企業現時的營銷策略</h2>
                <p className="font24px fontWeight400">在過去12個月裡，企業主要用社交媒體內容行銷(71.1%)，Facebook / Instagram廣告(52.6%)，Google搜尋廣告 (26.3%) 作推廣。</p>
                <div>
                   <CardContainer></CardContainer>
                </div>
            </div>
            <div className="indexBannerFourth">

            </div>
            <div className="indexBannerFifth">
                <h2 className="font36px fontWeight900">當中，新客戶來自：</h2>
                <div className="imgContainer">
                    <img src={pieChart} alt="" />
                </div>
                <PieChartList />
                <div className="line"></div>
                <div className="bottleContainer">
                    <div>
                        <p className="font24px">事實上，有超過 <span className="colorBlue font64px fontWeight600">50%</span> 的企業表示不滿意過去12個月的營銷策略效能</p>
                        <div className="imgContainer">
                            <img src={bottleOne} alt="" />
                        </div>
                    </div>
                    <div>
                        <p className="font24px">同時， 超過 <span className="colorBlue font64px fontWeight600">97%</span> 的企業表示營銷策略是有改進空間。</p>
                        <div className="imgContainer">
                            <img src={bottleTwo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="indexBannerSixth">
                <h2 className="colorWhite font72px fontWeight900">企業對數碼營銷策略態度</h2>
                <p className="colorWhite font24px fontWeight400">在過去的12個月裡，企業是如何執行數碼營銷？</p>
                <div>
                    <CircleChartList />
                </div>
            </div>
            <div className="indexBannerSeven">
                <h2 className="colorBlue font72px fontWeight900">企業對數碼營銷的期望</h2>
                <p className="colorBlack font24px fontWeight400">那企業在執行數碼營銷時面臨什麼挑戰呢？</p>
                <img src={bannerSeven} alt="" />
                <BarChartList />
            </div>
            <div className="indexBannerNine">
                <img src={bannerNine} alt="" />
                <NineBarGraphList />
            </div>
            <div className="indexBannerTen">
                <div className="indexBannerTen_mobile">
                    <h2 className="font72px fontWeight900 indexBannerTen_Text1">立即下載報告！</h2>
                    <h2 className="font72px fontWeight900 indexBannerTen_Text2">為你的決策做好準備！</h2>
                </div>
                <div className="formDownloadSub_Box">
                    <FormDownloadSub />
                </div>
            </div>
            <div className="indexBannerEnd">
                <h2 className="font72px fontWeight900 indexBannerEnd_Title">馬上報名！</h2>
                <p className="font24px fontWeight400 indexBannerEnd_Sub">為你的團隊提供全面的資訊科技培訓課程！</p>
                <a href="https://techonfire.com.hk/"><button className="font20px fontWeight700 indexBannerEnd_Btn">了解更多課程</button></a>
            </div>
            <footer></footer>
        </>
    );
};

export default App;
