import $ from 'jquery';
import { useEffect } from 'react';

const Header = () => {
    
    useEffect(() => {
        $("#menu-item-3430 > .sub-menu > .menu-item").click(function(){
            var submenu = $(this).find('.sub-menu');
            $('.coursemenu').removeClass('coursemenu');
            $(submenu).addClass('coursemenu');
        });

        $(".ast-button-wrap .menu-toggle").click(function(){
            $(".ast-button-wrap .menu-toggle").toggleClass('toggled');
            if($(".ast-button-wrap .menu-toggle").hasClass('toggled')){
                $('.main-header-bar-navigation').addClass('toggled-on');
            }else{
                $('.main-header-bar-navigation').removeClass('toggled-on');
            }
        });

        // $("#menu-item-3430").click(function(){
        //     // $(".#menu-item-3430 > .sub-menu").toggleClass('toggled');
        //     if($("#menu-item-3430 > .sub-menu").hasClass('toggled-on')){
        //         $('#menu-item-3430 > .sub-menu').addClass('toggled-on');
        //     }else{
        //         $('#menu-item-3430 > .sub-menu').removeClass('toggled-on');
        //     }
        // });

    }, [])
    
  return (
    <header
      className="site-header ast-primary-submenu-animation-fade header-main-layout-1 ast-primary-menu-enabled ast-logo-title-inline ast-hide-custom-menu-mobile ast-menu-toggle-icon ast-mobile-header-inline"
      id="masthead"
      itemType="https://schema.org/WPHeader"
      itemScope="itemscope"
      itemID="#masthead"
    >
      <div className="main-header-bar-wrap ">
        <div className="main-header-bar">
          <div className="ast-container">
            <div className="ast-flex main-header-container">
              <div className="site-branding">
                <div
                  className="ast-site-identity"
                  itemType="https://schema.org/Organization"
                  itemScope="itemscope"
                >
                  <span className="site-logo-img">
                    <a role="button"
                      href="https://techonfire.com.hk/hk/"
                      className="custom-logo-link"
                      rel="home"
                    >
                      <img
                        width="201"
                        height="58"
                        src="https://techonfire.com.hk/wp-content/uploads/2021/11/techonfire_logo-1-201x58.png"
                        className="custom-logo"
                        alt="IT Corporate Training &#8211; Tech On Fire"
                        srcSet="https://techonfire.com.hk/wp-content/uploads/2021/11/techonfire_logo-1-201x58.png 201w, https://techonfire.com.hk/wp-content/uploads/2021/11/techonfire_logo-1-18x5.png 18w, https://techonfire.com.hk/wp-content/uploads/2021/11/techonfire_logo-1.png 256w"
                        sizes="(max-width: 201px) 100vw, 201px"
                      />
                    </a>
                  </span>{" "}
                </div>
              </div>
              <div className="ast-mobile-menu-buttons">
                <div className="ast-button-wrap">
                  <button
                    type="button"
                    className="menu-toggle main-header-menu-toggle  ast-mobile-menu-buttons-minimal"
                    aria-controls="primary-menu"
                    aria-expanded="false"
                  >
                    <span
                      className="screen-reader-text"
                      data-no-translation=""
                      data-trp-gettext=""
                    >
                      Main Menu
                    </span>
                    <span className="ast-icon icon-menu-bars">
                      <span className="menu-toggle-icon"></span>
                    </span>{" "}
                  </button>
                </div>
              </div>
              <div className="ast-main-header-bar-alignment">
                <div className="main-header-bar-navigation">
                  <nav
                    className="ast-flex-grow-1 navigation-accessibility"
                    id="site-navigation"
                    aria-label="Site Navigation"
                    itemType="https://schema.org/SiteNavigationElement"
                    itemScope="itemscope"
                    data-no-translation-aria-label=""
                  >
                    <div className="main-navigation">
                      <ul
                        id="primary-menu"
                        className="main-header-menu ast-nav-menu ast-flex ast-justify-content-flex-end  submenu-with-border astra-menu-animation-fade  ast-mega-menu-enabled"
                      >
                        <li
                          id="menu-item-3430"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3430"
                        >
                          <a role="button"
                            href="#"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">培訓課程</span>
                            <span className="sub-arrow"></span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span
                              className="screen-reader-text"
                              data-no-translation=""
                              data-trp-gettext=""
                            >
                              Menu Toggle
                            </span>
                            <span className="ast-icon icon-arrow"></span>
                          </button>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-1003499"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003499"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  行業導向課程
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006999"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006999"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008558"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008558"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/digital-transformation-for-catering-industry%ef%bf%bc/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      飲食業的數碼轉型
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008210"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008210"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/digital-transformation-of-beauty-and-salon-industry/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      美容美髮業的數碼轉型
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2007779"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2007779"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/certificate-in-interactive-learning-platform-development/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      互動教學平台開發證書
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003480"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003480"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  行銷科技
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006961"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006961"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008288"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008288"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/search-engine-marketing-analytical-skills/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      搜尋器營銷及Google數據分析技巧
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008276"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008276"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/social-media-marketing-tactics-for-sme/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      中小企社交媒體營銷策略
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2006811"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2006811"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/digital-marketing-automation-program/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      數碼營銷課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2006825"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2006825"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/advanced-website-development-and-seo/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      企業網站製作及搜尋器優化課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2006798"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2006798"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/data-driven-and-automation-for-growth-marketer/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      數據導向與營銷自動化課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2006652"
                                  className="hee menu-item menu-item-type- menu-item-object- current-menu-item menu-item-2006652"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/social-media-marketing-bootcamp/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      社交媒體營銷課程
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003481"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003481"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  電商 / 網紅
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006963"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006963"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008565"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008565"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/digital-personal-branding-marketing/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      數碼化個人品牌行銷
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008268"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008268"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/video-marketing-and-digital-advertising-strategies/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      短片營銷和數碼廣告策略
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008022"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008022"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/live-streaming-e-commerce-for-sme/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      中小企業直播及網上銷售課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2007869"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2007869"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/digital-transformation-for-retail-industry/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      零售業的數碼轉型
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2007680"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2007680"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/video-production-for-e-commerce/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      電子商務影片製作及網上直播課程
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003482"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003482"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  中國數碼營銷
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006965"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006965"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008026"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008026"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/%e5%85%a7%e5%9c%b0%e8%87%aa%e5%aa%92%e9%ab%94%e5%8f%8a%e7%9b%b4%e6%92%ad%e7%87%9f%e9%8a%b7%e5%9f%b9%e8%a8%93/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      內地自媒體及直播營銷培訓
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008024"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008024"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/%e4%b8%ad%e5%9c%8b%e7%a4%be%e4%ba%a4%e5%aa%92%e9%ab%94%e7%87%9f%e9%8a%b7%e8%aa%b2%e7%a8%8b/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      中國社交媒體營銷課程
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003483"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003483"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  使用者介面及體驗 (UIUX)
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006967"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006967"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2007154"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2007154"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/ui-ux-bootcamp/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      全端 UIUX 訓練
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2007152"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2007152"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/ui-ux-digital-project-management/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      用戶體驗設計課程 (進階)
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003484"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003484"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  AR &amp; VR
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006969"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006969"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2006981"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2006981"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/augmented-reality-for-education/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      AR擴充實景教材製作課程
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003485"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003485"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  機械人流程自動化 (RPA)
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006971"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006971"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2007193"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2007193"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/robotic-processing-automation-program/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      機械人流程自動化課程
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003487"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003487"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  商業智能
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006975"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006975"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008012"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008012"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/data-analysis-by-business-intelligence-for-sme/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      中小企數據分析商業智能課程
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-1003488"
                              className="hee menu-item menu-item-type- menu-item-object- menu-item-has-children menu-item-1003488"
                            >
                              <a role="button"
                                href="#"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  行政人員IT工作坊
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span
                                  className="screen-reader-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  Menu Toggle
                                </span>
                                <span className="ast-icon icon-arrow"></span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-3006977"
                                  className="submenu-header menu-item menu-item-type- menu-item-object- menu-item-3006977"
                                >
                                  <a role="button"
                                    href="#"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      課程
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008612"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008612"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/fast-track-to-robotic-process-automation/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      RPA速成班
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008078"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008078"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/design-thinking-workshop/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      思維設計工作坊
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008079"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008079"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/applying-blockchain-technology-and-smart-contract-technology-in-business/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      應用區塊鏈及智能合約技術
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008077"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008077"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/targeted-advertisement-placement-best-practices-right-place-right-time/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      目標式廣告策略:如何在對的時間找到對的客戶
                                    </span>
                                  </a>
                                </li>{" "}
                                <li
                                  id="menu-item-2008074"
                                  className="hee menu-item menu-item-type- menu-item-object- menu-item-2008074"
                                >
                                  <a role="button"
                                    href="https://techonfire.com.hk/hk/it_training/seo-training-get-free-traffic-to-your-website/"
                                    className="menu-link"
                                    data-ps2id-api="true"
                                  >
                                    <span
                                      className="menu-text"
                                      data-no-translation=""
                                      data-trp-gettext=""
                                    >
                                      SEO 培訓：爭取免費網站流量
                                    </span>
                                  </a>
                                </li>{" "}
                              </ul>
                            </li>{" "}
                            <li
                              id="menu-item-11003429"
                              className="border_top menu-item menu-item-type- menu-item-object- menu-item-11003429"
                            >
                              <a role="button"
                                href="https://techonfire.com.hk/hk/all-course/"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span
                                  className="menu-text"
                                  data-no-translation=""
                                  data-trp-gettext=""
                                >
                                  <i className="far fa-file-alt"></i> 全部課程
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-4675"
                          className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-4675"
                        >
                          <a role="button"
                            href="#"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">Bootcamp</span>
                            <span className="sub-arrow"></span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span
                              className="screen-reader-text"
                              data-no-translation=""
                              data-trp-gettext=""
                            >
                              Menu Toggle
                            </span>
                            <span className="ast-icon icon-arrow"></span>
                          </button>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-4676"
                              className="menu-item menu-item-type-post_type menu-item-object-it_training current-menu-item menu-item-4676"
                            >
                              <a role="button"
                                href="https://techonfire.com.hk/hk/it_training/social-media-marketing-bootcamp/"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span className="menu-text">
                                  社交媒體營銷課程
                                </span>
                              </a>
                            </li>{" "}
                            <li
                              id="menu-item-4677"
                              className="menu-item menu-item-type-post_type menu-item-object-it_training menu-item-4677"
                            >
                              <a role="button"
                                href="https://techonfire.com.hk/hk/it_training/ui-ux-bootcamp/"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span className="menu-text">
                                  UI UX Bootcamp
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-1620"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1620"
                        >
                          <a role="button"
                            href="https://techonfire.com.hk/hk/tailor-made-corporate-training-program/"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">按企業需要培訓</span>
                            <span className="sub-arrow"></span>
                          </a>
                        </li>
                        <li
                          id="menu-item-3408"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3408"
                        >
                          <a role="button"
                            href="https://techonfire.com.hk/hk/it_events/"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">最新活動</span>
                            <span className="sub-arrow"></span>
                          </a>
                        </li>
                        <li
                          id="menu-item-3965"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3965"
                        >
                          <a role="button"
                            href="https://techonfire.com.hk/hk/rttp%e8%b3%87%e5%8a%a9%e8%aa%b2%e7%a8%8b/"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">RTTP培訓資助</span>
                            <span className="sub-arrow"></span>
                          </a>
                        </li>
                        <li
                          id="menu-item-1624"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1624"
                        >
                          <a role="button"
                            href="https://techonfire.com.hk/hk/about-us/"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">關於TOF</span>
                            <span className="sub-arrow"></span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span
                              className="screen-reader-text"
                              data-no-translation=""
                              data-trp-gettext=""
                            >
                              Menu Toggle
                            </span>
                            <span className="ast-icon icon-arrow"></span>
                          </button>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-3820"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3820"
                            >
                              <a role="button"
                                href="https://techonfire.com.hk/hk/technology-tips/"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span className="menu-text">數碼轉型知識</span>
                              </a>
                            </li>{" "}
                            <li
                              id="menu-item-4240"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4240"
                            >
                              <a role="button"
                                href="https://techonfire.com.hk/hk/instructors/"
                                className="menu-link"
                                data-ps2id-api="true"
                              >
                                <span className="menu-text">導師</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        {/* <li
                          id="menu-item-3661"
                          className="tp_en trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher menu-item-3661"
                        >
                          <a role="button"
                            href="https://techonfire.com.hk/it_training/social-media-marketing-bootcamp/"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">
                              <span data-no-translation>
                                <span className="trp-ls-language-name">EN</span>
                              </span>
                            </span>
                            <span className="sub-arrow"></span>
                          </a>
                        </li> */}
                        {/* <li
                          id="menu-item-3660"
                          className="tp_hk trp-language-switcher-container menu-item menu-item-type-post_type menu-item-object-language_switcher current-language-menu-item menu-item-3660"
                        >
                          <a role="button"
                            href="https://techonfire.com.hk/hk/it_training/social-media-marketing-bootcamp/"
                            className="menu-link"
                            data-ps2id-api="true"
                          >
                            <span className="menu-text">
                              <span data-no-translation>
                                <span className="trp-ls-language-name">HK</span>
                              </span>
                            </span>
                            <span className="sub-arrow"></span>
                          </a>
                        </li>{" "} */}
                        <li className="ast-masthead-custom-menu-items button-custom-menu-item">
                          <a role="button"
                            className="ast-custom-button-link"
                            href="https://techonfire.com.hk/hk/contact-us/"
                            target="_self"
                          >
                            <div className="ast-custom-button">報名表格</div>
                          </a>
                          {/* <a role="button"
                            className="menu-link"
                            href="https://techonfire.com.hk/hk/contact-us/"
                            target="_self"
                          >
                            報名表格
                          </a>{" "} */}
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
