import parse from "html-react-parser"
const data = [
    { text: "社群媒體內容行銷", backgroundColor: "#F3CE2A" },
    {
        text: "<span>Facebook / Instagram</span>廣告、<span>Google</span>搜尋廣告及短片拍攝",
        backgroundColor: "#73C3AE",
    },
    { text: "直播帶貨", backgroundColor: "#E14966" },
    { text: "參加行業展覽", backgroundColor: "#F3A727" },
    {
        text: "線下活動，如派發傳單、試食攤位等",
        backgroundColor: "#5BBEDB",
    },
        {
        text: "<span>SEO</span> 搜索引擎最佳化、<span>KOL</span>代言/博客宣傳",
        backgroundColor: "#E16A9F",
    },
    { text: "<span>YouTube廣告</span>", backgroundColor: "#796DAB" },
];
const List = ({ text, backgroundColor }) => {
    return (
        <div className="list">
            <div
                className="circle"
                style={{ backgroundColor: backgroundColor }}
            ></div>
            <p className="font16px fontWeight600">{parse(text)}</p>
        </div>
    );
};
const PieChartList = () => {
    return (
        <div className="listContainer">
            {data.map((item, index) => (
                <List
                    key={index}
                    text={item.text}
                    backgroundColor={item.backgroundColor}
                />
            ))}
        </div>
    );
};
export default PieChartList;
