import NineBarGraph from "./NineBarGraph.js";
const data = [
    { number: '39.1%', text: '於公司內部挑選及培育人才'},
    { number: '34.8%', text: '聘用數碼營銷代理機構'},
    { number: '13%', text: '聘請相關人才'},
    { number: '13%', text: '親自執行'},
];

const NineBarGraphList = () => {
    return (
        <div className="BarGraph_container">
            <h2 className="font48px fontWeight900">應對數碼轉型人力資源</h2>
            <p className="font24px fontWeight400 BarGraph_sub">於是，近40%企業傾向於內部挑選及培育人才，另有34.8%企業表示傾向聘用數碼營銷代理機構來應付數碼轉型的需要。 </p>
            {data.map((item, index) => {
                return <NineBarGraph number={item.number} text={item.text} key={index} />
            })}
        </div>
    );
};


export default NineBarGraphList;
