import CircleChart from "./CircleChart";

const data = [
    { text: "傾向老闆親自執行數碼營銷工作", image: "CircleChart1" },
    { text: "聘用數碼營銷代理機構", image: "CircleChart2" },
    { text: "於企業內部挑選及培育人才", image: "CircleChart3" },
    { text: "聘請相關人才來 執行數碼營銷工作", image: "CircleChart4" },
];
const CircleChartList = () => {
    return (
        <div className="circleChartContainer">
            {data.map((item,index)=>{
               return <CircleChart image={item.image} text={item.text} key={index} />
            })}
        </div>
    );
};
export default CircleChartList;
