import checkImage from "../image/check.svg";
import testPHP from "../php/test.php";
import { useState } from "react";
import axios from "axios";
import ThankYou from "./ThankYou";
const FormDownload = () => {
    const emailValid =
        /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
    const [checkboxValueFirst, setCheckboxValueFirst] = useState("Yes");
    const [checkboxValueSecond, setCheckboxValueSecond] = useState("Yes");
    const [complete, setComplete] = useState(false);
    const [vertify, setVertify] = useState(false);
    const [emailVertify, setEmailVertify] = useState(false);
    const [emptyVertify, setEmptyVertify] = useState(false);
    const checkboxValue1 = (e) => {
        if (e.target.checked) {
            setCheckboxValueFirst("Yes");
        } else {
            setCheckboxValueFirst("No");
        }
    };
    const checkboxValue2 = (e) => {
        if (e.target.checked) {
            setCheckboxValueSecond("Yes");
        } else {
            setCheckboxValueSecond("No");
        }
    };
    const insertData = () => {
        const params = new URLSearchParams();
        params.append("name", document.getElementById("userInfoName").value);
        params.append(
            "industry",
            document.getElementById("userInfoIndustry").value
        );
        params.append("infojob", document.getElementById("userInfoJob").value);
        params.append("email", document.getElementById("userInfoEmail").value);
        params.append("checkboxFirst", checkboxValueFirst);
        params.append("checkboxSecond", checkboxValueSecond);
        document.querySelectorAll(".formData").forEach((item, index) => {
            if (item.value === "") {
                item.style.border = "1px solid red";
            } else {
                item.style.border = "none";
            }

        });
        if (
            !emailValid.test(document.getElementById("userInfoEmail").value)
        ) {
            setEmailVertify(true);
            document.getElementById("userInfoEmail").style.border =
                "1px solid red";
        }else{
            setEmailVertify(false)
            document.getElementById('userInfoEmail').style.border = 'none'
        }
        if(document.getElementById("userInfoName").value === "" ||
        document.getElementById("userInfoIndustry").value === "" ||
        document.getElementById("userInfoJob").value === "" ){
            setEmptyVertify(true)
        }else{
            setEmptyVertify(false)
        }
        if (
            document.getElementById("userInfoName").value !== "" &&
            document.getElementById("userInfoIndustry").value !== "" &&
            document.getElementById("userInfoJob").value !== "" &&
            emailValid.test(document.getElementById("userInfoEmail").value)
        ) {
            axios.post(testPHP, params).then((res) => {
                if (res.data === "新增成功") {
                    document.getElementById("userInfoName").value = "";
                    document.getElementById("userInfoIndustry").value = "";
                    document.getElementById("userInfoJob").value = "";
                    document.getElementById("userInfoEmail").value = "";
                    document.getElementById("newInfo").checked = false;
                    document.getElementById("classInfo").checked = false;
                    setCheckboxValueSecond("No");
                    setCheckboxValueFirst("No");
                    setComplete(true);
                }
            });
        } else {
            setVertify(true);
        }
    };
    return (
        <form className="formTransform">
            <div className="formOne">
                <h3 className="colorOrange font24px fontWeight700">
                    Active Now!
                </h3>
                <p className="colorWhite font16px fontWeight400">
                    想率先掌握最新的數碼媒體營銷數據？
                </p>
                <h2 className="colorWhite font32px fontWeight900">
                    立即下載報告！
                </h2>
                <h2 className="colorWhite font32px fontWeight900 marginBottom">
                    為你的決策做好準備！
                </h2>
                {complete ? (
                    <ThankYou />
                ) : (
                    <>
                        <input
                            type="text"
                            className="formData"
                            placeholder="稱呼"
                            id="userInfoName"
                        />
                        <input
                            type="text"
                            className="formData"
                            placeholder="行業"
                            id="userInfoIndustry"
                        />
                        <input
                            type="text"
                            className="formData"
                            placeholder="職位"
                            id="userInfoJob"
                        />
                        <input
                            type="email"
                            className="formData"
                            placeholder="Email"
                            id="userInfoEmail"
                        />
                        {vertify && (
                            <div className="alertForm">
                                {emailVertify && (
                                    <p>
                                        <span>*</span>請輸入正確的Email
                                    </p>
                                )}
                                {emptyVertify && (
                                    <p>
                                        <span>*</span>請輸入必塡欄位
                                    </p>
                                )}
                            </div>
                        )}

                        <div className="newInfoDiv">
                            <input
                                type="checkbox"
                                id="newInfo"
                                value="Yes"
                                onInput={(e) => checkboxValue1(e)}
                                defaultChecked
                            />
                            <label htmlFor="newInfo" className="checkbox">
                                <img
                                    src={checkImage}
                                    alt=""
                                    className="checkImg"
                                />
                            </label>
                            <label htmlFor="newInfo" className="colorWhite">
                                我想收到Tech on Fire的最新資訊
                            </label>
                        </div>
                        <div className="classInfoDiv">
                            <input
                                type="checkbox"
                                id="classInfo"
                                value="Yes"
                                onInput={(e) => checkboxValue2(e)}
                                defaultChecked
                            />
                            <label htmlFor="classInfo" className="checkbox">
                                <img
                                    src={checkImage}
                                    alt=""
                                    className="checkImg"
                                />
                            </label>
                            <label htmlFor="classInfo" className="colorWhite">
                                有興趣了解Tech on Fire課程資訊
                            </label>
                        </div>

                        <div
                            className="colorWhite middleBtn fontWeight900"
                            onClick={insertData}
                        >
                            下載報告
                        </div>
                    </>
                )}
            </div>
        </form>
    );
};

export default FormDownload;
