const data = [
    { number: '60.5%', text: '沒有相應資源和配套', color:'#F2994A', width: '144px'},
    { number: '50%', text: '不懂得設定合適的預算用作轉型', color: '#F3CE2C', width: '160px' },
    { number: '34.2%', text: '追上瞬息萬變的網路發展', color: '#5BBEDB', width: '180px' },
    { number: '26.9%', text: '進行大數據分析以作出相應決策', color: '#E14966', width: '128px' },
    { number: '15.8%', text: '了解並迎合客戶的要求、 挑選個組合公司的營銷策略', color: '#72C3AE', width: '195px' },
]

const BarChart = ({number, text, color, width}) => {
    return (
        <div className="barChart_item" style={{width: width}}>
            <h3 className="font36px fontWeight700" style={{color: color}}>{number}</h3>
            <p className="font16px fontWeight700">{text}</p>
        </div>
    );
};

const BarChartList = () => {
    return (
        <div className="barChart_container">
            {data.map((item, index) => (
                <BarChart number={item.number} text={item.text} key={index} color={item.color} width={item.width}/>
            ))}
        </div>
    );
};

export default BarChartList;