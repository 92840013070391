import testPDF from "../pdf/test.pdf";
const ThankYou = ()=>{
    return(
        <div className="thankYouDiv">
            <h2 className="colorOrange font72px thankYou" style={{marginBottom: '50px'}}>Thank You</h2>
            <a      
                    className="colorWhite middleBtn resetFormTwo"
                    style={{width: '100%',background: 'linear-gradient(105.95deg, #FFB370 24.79%, #FF7800 71.87%)',height: '50px',lineHeight: '50px'}}
                    href={testPDF}
                    download="報告.pdf"
                    rel="noopener"
                    id="downloadPDF"
                >下載報告</a>
        </div>
    )
}
export default ThankYou