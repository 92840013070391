import checkImage from "../image/check.svg";
import testPHP from "../php/test.php";
import { useState } from "react";
import axios from "axios";
import ThankYou from "./ThankYou";
const FormDownloadSub = () => {

    const emailValid =
        /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
    const [checkboxValueFirst, setCheckboxValueFirst] = useState("Yes");
    const [checkboxValueSecond, setCheckboxValueSecond] = useState("Yes");
    const [complete, setComplete] = useState(false);
    const [vertify, setVertify] = useState(false);
    const [emailVertify, setEmailVertify] = useState(false);
    const [emptyVertify, setEmptyVertify] = useState(false);
    const checkboxValue1 = (e) => {
        if (e.target.checked) {
            setCheckboxValueFirst("Yes");
        } else {
            setCheckboxValueFirst("No");
        }
    };
    const checkboxValue2 = (e) => {
        if (e.target.checked) {
            setCheckboxValueSecond("Yes");
        } else {
            setCheckboxValueSecond("No");
        }
    };
    const insertData = () => {
        const params = new URLSearchParams();
        params.append("name", document.getElementById("userInfoName2").value);
        params.append(
            "industry",
            document.getElementById("userInfoIndustry2").value
        );
        params.append("infojob", document.getElementById("userInfoJob2").value);
        params.append("email", document.getElementById("userInfoEmail2").value);
        params.append("checkboxFirst", checkboxValueFirst);
        params.append("checkboxSecond", checkboxValueSecond);
        document.querySelectorAll(".formDataSecond").forEach((item, index) => {
            if (item.value === "") {
                item.style.border = "1px solid red";
            } else {
                item.style.border = "none";
            }
        });
        if (
            !emailValid.test(document.getElementById("userInfoEmail2").value)
        ) {
            setEmailVertify(true);
            document.getElementById("userInfoEmail2").style.border =
                "1px solid red";
        }else{
            setEmailVertify(false)
            document.getElementById('userInfoEmail2').style.border = 'none'
        }
        if(document.getElementById("userInfoName2").value === "" ||
        document.getElementById("userInfoIndustry2").value === "" ||
        document.getElementById("userInfoJob2").value === "" ){
            setEmptyVertify(true)
        }else{
            setEmptyVertify(false)
        }
        if (
            document.getElementById("userInfoName2").value !== "" &&
            document.getElementById("userInfoIndustry2").value !== "" &&
            document.getElementById("userInfoJob2").value !== "" &&
            emailValid.test(document.getElementById("userInfoEmail2").value)
        ) {
            axios.post(testPHP, params).then((res) => {
                if (res.data === "新增成功") {
                    document.getElementById("userInfoName2").value = "";
                    document.getElementById("userInfoIndustry2").value = "";
                    document.getElementById("userInfoJob2").value = "";
                    document.getElementById("userInfoEmail2").value = "";
                    document.getElementById("newInfoSecond").checked = false;
                    document.getElementById("classInfoSecond").checked = false;
                    setCheckboxValueSecond("No");
                    setCheckboxValueFirst("No");
                    setComplete(true);
                }
            });
        } else {
            setVertify(true);
        }
    };
    return (
        <form className="formDownloadSub">
            <div className="formTwo">
                <div className="formTwo_item">
                    <h2 className="colorWhite font32px fontWeight900 marginBottom">
                        想率先掌握最新的數碼媒體營銷數據？即時為你的決策做好準備！
                    </h2>
                </div>
                {complete ? (
                    <ThankYou />
                ) : (
                    <div className="formTwo_item">
                        <p className="colorWhite font24px fontWeight700">
                            即時與我們聯繫
                        </p>
                        <input
                            type="text"
                            className="formDataSecond"
                            placeholder="稱呼"
                            id="userInfoName2"
                        />
                        <input
                            type="text"
                            className="formDataSecond"
                            placeholder="行業"
                            id="userInfoIndustry2"
                        />
                        <input
                            type="text"
                            className="formDataSecond"
                            placeholder="職位"
                            id="userInfoJob2"
                        />
                        <input
                            type="text"
                            className="formDataSecond"
                            placeholder="Email"
                            id="userInfoEmail2"
                        />
                        {vertify && (
                            <div className="alertForm">
                                {emailVertify && (
                                    <p>
                                        <span>*</span>請輸入正確的Email
                                    </p>
                                )}
                                {emptyVertify && (
                                    <p>
                                        <span>*</span>請輸入必塡欄位
                                    </p>
                                )}
                            </div>
                        )}
                        <div className="newInfoDiv">
                            <input
                                type="checkbox"
                                id="newInfoSecond"
                                onInput={(e) => checkboxValue1(e)}
                                defaultChecked
                            />
                            <label htmlFor="newInfoSecond" className="checkbox">
                                <img
                                    src={checkImage}
                                    alt=""
                                    className="checkImg"
                                />
                            </label>
                            <label
                                htmlFor="newInfoSecond"
                                className="colorWhite"
                            >
                                我想收到Tech on Fire的最新資訊
                            </label>
                        </div>
                        <div className="classInfoDiv">
                            <input
                                type="checkbox"
                                id="classInfoSecond"
                                onInput={(e) => checkboxValue2(e)}
                                defaultChecked
                            />
                            <label
                                htmlFor="classInfoSecond"
                                className="checkbox"
                            >
                                <img
                                    src={checkImage}
                                    alt=""
                                    className="checkImg"
                                />
                            </label>
                            <label
                                htmlFor="classInfoSecond"
                                className="colorWhite"
                            >
                                有興趣了解Tech on Fire課程資訊
                            </label>
                        </div>
                        <div
                            className="colorWhite middleBtn fontWeight900"
                            onClick={insertData}
                        >
                            下載報告
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
};

export default FormDownloadSub;
