const NineBarGraph = ({number, text}) => {
    return (
        <div className="BarGraph_item">
            <p className="font24px fontWeight900 BarGraph_item_number">{number}</p>
            <div className="BarGraphBox">
                <div className="BarGraphBox_item"></div>
            </div>
            <p className="font24px fontWeight700 BarGraph_item_text">{text}</p>
        </div>
    )
};


export default NineBarGraph;
