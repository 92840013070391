const Card = ({image,number,text})=>{
    return (
        <div className="card">
            <div className="imgContainer">
                <img src={require(`../image/${image}.svg`) } alt="" />
            </div>
            <h3 className="colorOrange font36px fontWeight700">{number}%</h3>
            <p className="font16px fontWeight700">{text}</p>
        </div>
    )
}

export default Card;