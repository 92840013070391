import Card from './Card';
const data = [{name: 'Card1',number: 36.8 , text: "短片拍攝"},
{name: 'Card2',number: 23.7 , text: "線下活動，如派發傳單、試食攤位等"},
{name: 'Card3',number: 21.1 , text: "直播帶貨"},
{name: 'Card4',number: 18.4 , text: "參加行業展覽"},
{name: 'Card5',number: 15.8 , text: "SEO 搜尋引勤最佳化"},
{name: 'Card6',number: 13.2 , text: "KOL代言/博客宣傳"},
{name: 'Card7',number: 5.3 , text: "燈箱廣告"},
{name: 'Card8',number: 2.6 , text: "Youtube廣告、公共交通工具車身廣告、寫專欄"}]
const CardContainer = ()=>{
    return(
        <div className="cardContainer">
        {data.map((item,index)=><Card key={index} image={item.name} number={item.number} text={item.text} />)}
        </div>
    )
}

export default CardContainer;