const CircleChart = ({image,text}) =>{
    return (
        <div className="circleChart">
            <div>
                <img src={require(`../image/${image}.svg`) } alt="" />
            </div>
            <p className="colorWhite font18px">{text}</p>
        </div>
    )
}
export default CircleChart